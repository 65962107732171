import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageCacheService {
  private imageCache = new Map<string, File>();

  constructor() {}


  getImageFile(id: string): File | undefined | null {
    if (!this.imageCache.has(id)) {
      return null;
    }

    return this.imageCache.get(id);
  }


  cacheImage(id: string, file: File) {
    this.imageCache.set(id, file);
  }


  clearCache() {
    this.imageCache.clear();
  }

  base64ToFile(base64String: string, fileName = "profileImage.png"): File {
    const byteCharacters = atob(base64String);

    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" });

    return new File([blob], fileName, { type: "image/png" });
  }

  convertFileToDataUrl(file: File) {
    return new Observable<string | ArrayBuffer>(observer => {
      const reader = new FileReader();
      reader.onload = () => {
        observer.next(reader.result as string);
        observer.complete();
      };
      reader.onerror = error => observer.error(error);
      reader.readAsDataURL(file);
    });
  }
}
